







import LoginButton from '@/components/LoginButton.vue';
import LogoutButton from '@/components/LogoutButton.vue';
import {Component, Vue} from 'vue-property-decorator';

@Component({
  components: {LogoutButton, LoginButton},
})
export default class AdminBar extends Vue {}
