




import {Component, Vue} from 'vue-property-decorator';

@Component
export default class LogoutButton extends Vue {
  private logout(): void {
    this.$gapi.logout().then(() => {
      location.reload();
    });
  }
}
